import React from "react";
import Logo from "components/Common/Logo";

export default function Footer() {
  return (
    <div className="text-sm bg-background-200 border-background-300 border-t py-6">
      <div className="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
        <p className="text-foreground-300 text-center sm:text-left">
          © 2022 ThangisWho
        </p>
        <span className="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
          <Logo className="h-6 w-6 mr-2 -mt-2 flex" />
          <span className="">Let's have a coffee break!</span>
        </span>
      </div>
    </div>
  );
}
