import React from "react";
import Layout from "layouts/EmptyLayout";
import NotFound from "contents/NotFound";

export default function Page() {
  return (
    <Layout title="Not Found">
      <NotFound />
    </Layout>
  );
}
